<template>
  <div h="full" w="full" class="absolute" overflow-y="auto">
    <slot />
  </div>
</template>

<script lang="ts" setup>
await usePageConfig()
</script>

<style>

</style>
